<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">VISA TYPE</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearch()"
                v-model="search"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">VISA CATEGORY</th>
                      <th class="text-center text-uppercase">DETAIL</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index" v-if="data.detail.length > 0">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.jenis_visa }}</td>
                      <td class="text-center">
                        {{ data.detail }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="PilihStatusIjinTinggal(data.id_statusijintinggal, data.detail)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="page--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalCountry"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">COUNTRY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearchNegaraAsal()"
                v-model="searchNegaraAsal"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA NEGARA</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataNegaraAsal()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.nama_negara }}</td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="PilihNegaraAsal(data.id_negara, data.nama_negara)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="pageNegaraAsal--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              :class="['v-data-footer', pageNumber == pageNegaraAsal ? 'active' : '']"
                              v-for="pageNumber in pagesNegaraAsal.slice(pageNegaraAsal - 1, pageNegaraAsal + 5)"
                              :key="pageNumber"
                              @click="pageNegaraAsal = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal < pagesNegaraAsal.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageNegaraAsal++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalCitizenship"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">COUNTRY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearchNegaraAsal()"
                v-model="searchNegaraAsal"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA NEGARA</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataNegaraAsal()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.nama_negara }}</td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="PilihKewarganegaraan(data.id_negara, data.nama_negara)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="pageNegaraAsal--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              :class="['v-data-footer', pageNumber == pageNegaraAsal ? 'active' : '']"
                              v-for="pageNumber in pagesNegaraAsal.slice(pageNegaraAsal - 1, pageNegaraAsal + 5)"
                              :key="pageNumber"
                              @click="pageNegaraAsal = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal < pagesNegaraAsal.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageNegaraAsal++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalGroupFamily"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">GROUP FAMILY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearchGroupFamily()"
                v-model="searchGroupFamily"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA NEGARA</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataGroupFamily()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.nama_belakang }}</td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="pilihNamaBelakang(data.id_member, data.nama_belakang)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="pageGroupFamily--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupFamily == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              :class="['v-data-footer', pageNumber == pageGroupFamily ? 'active' : '']"
                              v-for="pageNumber in pagesGroupFamily.slice(pageGroupFamily - 1, pageGroupFamily + 5)"
                              :key="pageNumber"
                              @click="pageGroupFamily = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupFamily < pagesGroupFamily.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageGroupFamily++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalGroupCompany"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">GROUP COMPANY</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearchGroupCompany()"
                v-model="searchGroupCompany"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">COMPANY NAME</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataGroupCompany()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.nama_perusahaan }}</td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="pilihNamaPerusahaan(data.id_member, data.nama_perusahaan)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="pageGroupCompany--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupCompany == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              :class="['v-data-footer', pageNumber == pageGroupCompany ? 'active' : '']"
                              v-for="pageNumber in pagesGroupCompany.slice(pageGroupCompany - 1, pageGroupCompany + 5)"
                              :key="pageNumber"
                              @click="pageGroupCompany = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageGroupCompany < pagesGroupCompany.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageGroupCompany++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE CLIENT</v-card-title>
        <form @submit.prevent="CreateData" enctype="multipart/form-data" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col md="6" cols="12">
              <v-row no-gutters>
                <v-col cols="3" md="3">
                  <v-switch label="New" @click="ubahFamily(clients.group_keluarga)"></v-switch>
                </v-col>
                <v-col cols="6" md="9">
                  <v-text-field
                    label="Group Family"
                    :disabled="clients.group_keluarga"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalGroupFamily"
                    id="group_keluarga"
                    v-model="clients.nama_belakang"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                  <text
                    label="Group Family"
                    :disabled="clients.group_keluarga"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalGroupFamily"
                    id="namabelakangs"
                    dense
                    outlined
                    readonly
                  ></text>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="6" cols="12">
              <v-row no-gutters>
                <v-col cols="3" md="3">
                  <v-switch label="New" @click="ubahCompany(clients.group_perusahaan)"></v-switch>
                </v-col>
                <v-col cols="6" md="9">
                  <v-text-field
                    label="Group Company"
                    :disabled="clients.group_perusahaan"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalGroupCompany"
                    id="group_perusahaan"
                    v-model="clients.nama_perusahaan"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                  <text
                    label="Group Company"
                    :disabled="clients.group_perusahaan"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalGroupCompany"
                    id="perusahaans"
                    dense
                    outlined
                    readonly
                  ></text>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                class="required"
                label="First Name"
                v-model="clients.nama_depan"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                label="Last Name"
                id="nama_belakang"
                v-model="clients.nama_belakang"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Gender</v-header>
              <v-radio-group v-model="clients.jenis_kelamin" row>
                <v-radio label="Man" value="L" color="info"></v-radio>
                <v-radio label="Woman" value="P" color="error"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Marital Status</v-header>
              <v-radio-group v-model="clients.status_perkawinan" col>
                <v-radio label="Belum Menikah (Single)" value="BELUM MENIKAH" color="info"></v-radio>
                <v-radio label="Menikah (Married)" value="MENIKAH" color="warning"></v-radio>
                <v-radio label="Bercerai (divorce)" value="BERCERAI" color="error"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                label="Phone Number (without 062 / +62)"
                v-model="clients.no_telp"
                :prepend-inner-icon="icons.mdiCellphone"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                label="Current Address (Indonesia)"
                v-model="clients.alamat_diindonesia"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                label="Email"
                :rules="[rules.email]"
                v-model="clients.email"
                :prepend-inner-icon="icons.mdiEmailOutline"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field label="Telegram ID" v-model="clients.telegram_id" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCountry"
                label="Country of Origin"
                v-model="clients.countryoforigin"
                id="nama_negara"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCountry"
                label="Country of Origin"
                v-model="clients.negara_asal"
                id="id_negara"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Visa Type"
                v-model="clients.detail"
                id="detail"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Visa Type"
                v-model="clients.id_statusijintinggal"
                id="id_statusijintinggal"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCitizenship"
                label="Citizenship"
                v-model="clients.citizenship"
                id="nama_kewarganegaraan"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCitizenship"
                label="Citizenship"
                v-model="clients.kewarganegaraan"
                id="kewarganegaraan"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                label="Company Name"
                id="nama_perusahaan"
                v-model="clients.nama_perusahaan"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field label="Company Address" v-model="clients.alamat_kantor" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropper"
                    :key="showPhoto"
                    :aspect-ratio="2 / 2"
                    :src="showPhoto()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImage" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="input" type="file" accept="image/*" @change="setImage" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhoto()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'clients-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiEmailOutline, mdiCellphone, mdiCamera, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Clients',
          disabled: false,
          href: '/admin/clients',
        },
        {
          text: 'Update Client',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
      },

      rules: {
        // required: value => !!value || 'Required.',
        // counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      // rules: [value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!'],
      // selectedstatuspernikahan: clients.status_perkawinan,
      statuspernikahan: ['Belum Menikah', 'Menikah', 'Bercerai'],
      nama_kewarganegaraan: '',
      clients: [],
      errors: [],

      statusijintinggals: [],
      negaraasal: [],
      namabelakangs: [],
      perusahaans: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageNegaraAsal: 1,
      perPageNegaraAsal: 10,
      pagesNegaraAsal: [],
      searchNegaraAsal: '',
      searchResultNegaraAsal: [],

      pageGroupFamily: 1,
      perPageGroupFamily: 10,
      pagesGroupFamily: [],
      searchGroupFamily: '',
      searchResultGroupFamily: [],

      pageGroupCompany: 1,
      perPageGroupCompany: 10,
      pagesGroupCompany: [],
      searchGroupCompany: '',
      searchResultGroupCompany: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    this.getAllClient()
    this.getStatusIjinTinggal()
    this.getNegaraAsal()
    this.getNamaBelakang()
    this.getNamaPerusahaan()
    this.showPhoto()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getAllClient() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uriClients = process.env.VUE_APP_ROOT_API + `/api/members/${this.$route.params.id_member}/show`
      this.axios.get(uriClients).then(response => {
        this.clients = response.data.member
        console.log(this.members.email)
      })
    },
    getStatusIjinTinggal() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/statusijintinggals'
      return this.axios.get(url).then(response => {
        this.statusijintinggals = response.data.statusijintinggal
      })
    },

    getNegaraAsal() {
      //state token
      localStorage.getItem('token')

      let uriNegara = process.env.VUE_APP_ROOT_API + '/api/negaras'
      return this.axios.get(uriNegara).then(response => {
        this.negaraasal = response.data.negaras
      })
    },

    getNamaBelakang() {
      //state token
      localStorage.getItem('token')

      let uriNamaBelakang = process.env.VUE_APP_ROOT_API + '/api/members/namabelakangs'
      return this.axios.get(uriNamaBelakang).then(response => {
        this.namabelakangs = response.data.namabelakang
      })
    },

    getNamaPerusahaan() {
      //state token
      localStorage.getItem('token')

      let uriNamaPerusahaan = process.env.VUE_APP_ROOT_API + '/api/members/perusahaans'
      return this.axios.get(uriNamaPerusahaan).then(response => {
        this.perusahaans = response.data.perusahaan
      })
    },

    ubahFamily(group_keluarga) {
      this.clients.group_keluarga = !this.clients.group_keluarga
    },

    ubahCompany(group_perusahaan) {
      this.clients.group_perusahaan = !this.clients.group_perusahaan
    },

    // defautlPhoto() {
    //   let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
    //   return imageDefault
    // },

    updatePhoto() {
      if (this.clients.photo == null || this.clients.photo == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
        return imageDefault
      }

      let img = this.clients.photo

      if (img.length > 100) {
        return this.clients.photo
      } else {
        let imageClients =
          process.env.VUE_APP_ROOT_API + `/images/member/${this.clients.id_member}/${this.clients.photo}`
        return imageClients
      }
    },

    showPhoto() {
      if (typeof this.clients.photo === 'string' && this.clients.photo.length != 0) {
        if (this.clients.photo.length > 100) {
          return this.clients.photo
        }
        let imageClients =
          process.env.VUE_APP_ROOT_API + `/images/member/${this.clients.id_member}/${this.clients.photo}`
        return imageClients
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
      return imageDefault
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.clients.photo = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.clients.photo = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    CreateData(e) {
      // console.log('PHOTO: ' + this.clients.photo)
      if (this.$data.clients.nama_depan.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'First name required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.clients.id_statusijintinggal.length == 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Category visa required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/members/${this.$route.params.id_member}`

        this.axios
          .post(uri, {
            id_statusijintinggal: this.clients.id_statusijintinggal,
            nama_depan: this.clients.nama_depan,
            nama_belakang: this.clients.nama_belakang,
            negara_asal: this.clients.negara_asal,
            alamat_diindonesia: this.clients.alamat_diindonesia,
            email: this.clients.email,
            group_keluarga: this.clients.group_keluarga,
            group_perusahaan: this.clients.group_perusahaan,
            no_telp: this.clients.no_telp,
            telegram_id: this.clients.telegram_id,
            kewarganegaraan: this.clients.kewarganegaraan,
            status_perkawinan: this.clients.status_perkawinan,
            jenis_kelamin: this.clients.jenis_kelamin,
            nama_perusahaan: this.clients.nama_perusahaan,
            alamat_kantor: this.clients.alamat_kantor,
            photo: this.clients.photo,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Client created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
            setTimeout(() => {
              this.$router.push({ name: 'clients-index' })
            }, 3000)
          })
          .catch
          //   e => {
          //   this.$swal.fire({
          //     title: 'Error!',
          //     html: this.clients.email + ' sudah digunakan !',
          //     icon: 'error',
          //     showCancelButton: false,
          //     showConfirmButton: false,
          //     timer: 2000,
          //   })
          // }
          ()

        e.preventDefault()
      }
    },
    doSearch() {
      let go = this.statusijintinggals.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.detail.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.statusijintinggals)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.statusijintinggals.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(statusijintinggals) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return statusijintinggals.slice(from, to)
    },

    doSearchNegaraAsal() {
      let go = this.negaraasal.filter(item => {
        return this.searchNegaraAsal
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_negara.toLowerCase().includes(v))
      })
      this.searchResultNegaraAsal = go
      this.pagesNegaraAsal = []
      this.setPagesDataNegaraAsal()
    },
    displayDataNegaraAsal() {
      let paginateNegaraAsal

      if (this.searchNegaraAsal == '') {
        paginateNegaraAsal = this.paginateNegaraAsal(this.negaraasal)
      } else {
        paginateNegaraAsal = this.paginateNegaraAsal(this.searchResultNegaraAsal)
      }

      return paginateNegaraAsal
    },
    setPagesDataNegaraAsal() {
      let numberOfPages

      if (this.searchNegaraAsal == '') {
        numberOfPages = Math.ceil(this.negaraasal.length / this.perPageNegaraAsal)
      } else {
        numberOfPages = Math.ceil(this.searchResultNegaraAsal.length / this.perPageNegaraAsal)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesNegaraAsal.push(index)
      }
    },
    paginateNegaraAsal(negaraasal) {
      let pageNegaraAsal = this.pageNegaraAsal
      let perPageNegaraAsal = this.perPageNegaraAsal
      let from = pageNegaraAsal * perPageNegaraAsal - perPageNegaraAsal
      let to = pageNegaraAsal * perPageNegaraAsal
      return negaraasal.slice(from, to)
    },

    doSearchGroupFamily() {
      let go = this.namabelakangs.filter(item => {
        return this.searchGroupFamily
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_belakang.toLowerCase().includes(v))
      })
      this.searchResultGroupFamily = go
      this.pagesGroupFamily = []
      this.setPagesDataGroupFamily()
    },
    displayDataGroupFamily() {
      let paginateGroupFamily

      if (this.searchGroupFamily == '') {
        paginateGroupFamily = this.paginateGroupFamily(this.namabelakangs)
      } else {
        paginateGroupFamily = this.paginateGroupFamily(this.searchResultGroupFamily)
      }

      return paginateGroupFamily
    },
    setPagesDataGroupFamily() {
      let numberOfPages

      if (this.searchGroupFamily == '') {
        numberOfPages = Math.ceil(this.namabelakangs.length / this.perPageGroupFamily)
      } else {
        numberOfPages = Math.ceil(this.searchResultGroupFamily.length / this.perPageGroupFamily)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesGroupFamily.push(index)
      }
    },
    paginateGroupFamily(namabelakangs) {
      let pageGroupFamily = this.pageGroupFamily
      let perPageGroupFamily = this.perPageGroupFamily
      let from = pageGroupFamily * perPageGroupFamily - perPageGroupFamily
      let to = pageGroupFamily * perPageGroupFamily
      return namabelakangs.slice(from, to)
    },

    doSearchGroupCompany() {
      let go = this.perusahaans.filter(item => {
        return this.searchGroupCompany
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_perusahaan.toLowerCase().includes(v))
      })
      this.searchResultGroupCompany = go
      this.pagesGroupCompany = []
      this.setPagesDataGroupCompany()
    },
    displayDataGroupCompany() {
      let paginateGroupCompany

      if (this.searchGroupCompany == '') {
        paginateGroupCompany = this.paginateGroupCompany(this.perusahaans)
      } else {
        paginateGroupCompany = this.paginateGroupCompany(this.searchResultGroupCompany)
      }

      return paginateGroupCompany
    },
    setPagesDataGroupCompany() {
      let numberOfPages

      if (this.searchGroupCompany == '') {
        numberOfPages = Math.ceil(this.perusahaans.length / this.perPageGroupCompany)
      } else {
        numberOfPages = Math.ceil(this.searchResultGroupCompany.length / this.perPageGroupCompany)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesGroupCompany.push(index)
      }
    },

    paginateGroupCompany(perusahaans) {
      let pageGroupCompany = this.pageGroupCompany
      let perPageGroupCompany = this.perPageGroupCompany
      let from = pageGroupCompany * perPageGroupCompany - perPageGroupCompany
      let to = pageGroupCompany * perPageGroupCompany
      return perusahaans.slice(from, to)
    },

    PilihStatusIjinTinggal(id_statusijintinggal, detail) {
      document.querySelector('#id_statusijintinggal').setAttribute('value', id_statusijintinggal)
      document.querySelector('#detail').setAttribute('value', detail)
      this.clients.id_statusijintinggal = id_statusijintinggal
      this.clients.detail = detail
    },

    PilihNegaraAsal(id_negara, nama_negara) {
      document.querySelector('#id_negara').setAttribute('value', id_negara)
      document.querySelector('#nama_negara').setAttribute('value', nama_negara)
      this.clients.negara_asal = id_negara
      this.clients.countryoforigin = nama_negara
    },

    pilihNamaBelakang(id_member, nama_belakang) {
      document.querySelector('#namabelakangs').setAttribute('value', nama_belakang)
      this.clients.nama_belakang = nama_belakang
    },

    pilihNamaPerusahaan(id_member, nama_perusahaan) {
      document.querySelector('#perusahaans').setAttribute('value', nama_perusahaan)
      this.clients.nama_perusahaan = nama_perusahaan
    },

    PilihKewarganegaraan(id_negara, nama_negara) {
      document.querySelector('#id_negara').setAttribute('value', id_negara)
      document.querySelector('#nama_kewarganegaraan').setAttribute('value', nama_negara)
      this.clients.kewarganegaraan = id_negara
      this.clients.citizenship = nama_negara
    },
  },
  watch: {
    detail() {
      this.pages = []
      this.setPages()
    },

    negaraasal() {
      this.pagesNegaraAsal = []
      this.setPagesDataNegaraAsal()
    },

    namabelakangs() {
      this.pagesGroupFamily = []
      this.setPagesDataGroupFamily()
    },

    perusahaans() {
      this.pagesGroupCompany = []
      this.setPagesDataGroupCompany()
    },
  },
}
</script>
<style scoped>
.required >>> fieldset {
  border-color: rgba(250, 0, 29, 0.986);
}
</style>
